<template>
	<van-overlay :show="myVisible"
	             bind:click="onClickImgHide">
		<div class="popupRemind"
		     style="background: unset">
			<div class="context flex column align-unset">
				<img class="context-bg" :src="imgUrl"/>
				<div class="ml-6 text-gray">
					<p>① 日更新3条素材</p>
					<p class="mt-2">② 海报图自动转码</p>
					<p class="mt-2">③ 热销品牌丰富微信朋友圈</p>
				</div>
				<button class="context-button  button mt-4" @click="handleTarget">
					继续开启跟发吧~
				</button>
			</div>

			<div class="flex h-center mt-2"
			     @click="handleClose">
				<img src="../assets/icon_close.png" class="icon_close"/>
			</div>
		</div>

	</van-overlay>
</template>

<script>
export default {
	name: 'PopupRemind',
	props: {
		modelValue: {
			type: Boolean,
			default: false
		},
		imgUrl: {
			type: String,
			default: ''
		},
		imgWidth: {
			type: String,
			default: ''
		},
		imgHeight: {
			type: String,
			default: ''
		}
	},
	emits: ['update:modelValue'],
	computed: {
		myVisible: {
			set(val) {
				this.$emit('update:modelValue', val)
			},
			get() {
				return this.modelValue
			}
		}
	},
	methods: {
		handleClose() {
			this.myVisible = false
			this.$emit('confirm')
		},
		handleTarget() {
			this.handleClose()
			this.$router.push('/friendCircle')
		}
	}
}
</script>

<style scoped lang="scss">
.text-gray {
	color: #433C3C;
}
.popupRemind {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: px(300);
	border-radius: px(4);
	background-color: #fff;

	.context {
		width: 292px;
		height: 274px;
		background: linear-gradient(165deg, #FFF4F4 0%, #FFFFFF 100%);
		border-radius: 8px;

		.context-bg {
			width: 100%;
			height: 114px;
		}

		.context-text {

		}

		.context-button {
			align-self: center;
			width: 152px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 20px;
			border: 1px solid #FF545B;
			font-size: 14px;
			font-weight: 500;
			color: #FF545B;
		}
	}
}


.remind_img {
	width: px(300);
	height: px(305);
}

.icon_close {
	width: px(32);
	height: px(32);
}

</style>
